import React from "react"
import Container from "./Container"


export default function Footer() {
  return (
	<footer className="mt-36">
	<Container>
	<>
	</>
	</Container>
	</footer>
  )
}


import React from "react"


const Type = (props) => {	
	
const children = props.children; 
const type = props.type; 
const nopadding = props.nopadding;

//  Path Typography v1.0.0
// 	https://tailwindcss.com/docs/font-size
//  https://www.figma.com/file/RkcTaDASakftgdZdvFaHQ2/Library?node-id=204%3A113
	
	
	
	switch (type) {
		case "h1+":
			return ( <h1 className={nopadding==='yes' ? "text-5xl	lg:text-6xl	2xl:text-7xl	font-bold	mb-0" : "text-5xl	lg:text-6xl	2xl:text-7xl	font-bold  mb-8 lg:mb-12 2xl:mb-16"}>{children}</h1>  );
	  	case "h1":
			return ( <h1 className={nopadding==='yes' ? "text-4xl	lg:text-5xl	2xl:text-6xl	font-bold	mb-0" : "text-4xl	lg:text-5xl	2xl:text-6xl	font-bold	mb-8 lg:mb-12 2xl:mb-16"}>{children}</h1>  );
		case "h2":
			return ( <h2 className={nopadding==='yes' ? "text-3xl	lg:text-3xl	2xl:text-4xl	font-bold	mb-0" : "text-3xl	lg:text-3xl	2xl:text-4xl	font-bold	mb-4 lg:mb-8 2xl:mb-12"}>{children}</h2>  );
		case "h3":
			return ( <h3 className={nopadding==='yes' ? "text-xl	lg:text-2xl	2xl:text-3xl	font-bold	mb-0" : "text-xl	lg:text-2xl	2xl:text-3xl	font-bold	mb-4 lg:mb-8 2xl:mb-8"}>{children}</h3>  );
		case "h4+":
			return ( <h4 className={nopadding==='yes' ? "text-lg	lg:text-xl	2xl:text-2xl	font-bold	mb-0" : "text-lg	lg:text-xl	2xl:text-2xl	font-bold	mb-2 lg:mb-4 2xl:mb-8"}>{children}</h4>  );
		case "h4":
			return ( <h4 className={nopadding==='yes' ? "text-lg	lg:text-xl	2xl:text-2xl	font-bold	mb-0" : "text-lg	lg:text-xl	2xl:text-2xl	font-bold	mb-2 lg:mb-4 2xl:mb-8"}>{children}</h4>  );
		case "h5":
			return ( <h5 className={nopadding==='yes' ? "text-sm	lg:text-lg	2xl:text-xl	font-bold	mb-0" : "text-sm	lg:text-lg	2xl:text-xl	font-bold	mb-2 lg:mb-4 2xl:mb-8"}>{children}</h5>  );
		case "h6":
			return ( <h6 className={nopadding==='yes' ? "text-sm	lg:text-base	2xl:text-lg	font-bold	mb-0" : "text-sm	lg:text-base	2xl:text-lg	font-bold	mb-2 lg:mb-4 2xl:mb-8"}>{children}</h6>  );
		case "p1":
			return ( <p className={nopadding==='yes' ? "text-3xl	lg:text-5xl	2xl:text-6xl	font-light	mb-0" : "text-3xl	lg:text-5xl	2xl:text-6xl	font-light	mb-8 lg:mb-12 2xl:mb-16"}>{children}</p>  );
		case "p2":
			return ( <p className={nopadding==='yes' ? "text-2xl	lg:text-3xl	2xl:text-4xl	font-light	mb-0" : "text-2xl	lg:text-3xl	2xl:text-4xl	font-light	mb-4 lg:mb-8 2xl:mb-12"}>{children}</p>  );
		case "p3":
			return ( <p className={nopadding==='yes' ? "text-xl	lg:text-2xl	2xl:text-3xl	font-light	mb-0" : "text-xl	lg:text-2xl	2xl:text-3xl	font-light	mb-4 lg:mb-8 2xl:mb-8"}>{children}</p>  );
		case "p4":
			return ( <p className={nopadding==='yes' ? "text-lg	lg:text-xl	2xl:text-2xl	font-light	mb-0" : "text-lg	lg:text-xl	2xl:text-2xl	font-light	mb-2 lg:mb-4 2xl:mb-8"}>{children}</p>  );
		case "p5":
			return ( <p className={nopadding==='yes' ? "text-sm	lg:text-lg	2xl:text-xl	font-light	mb-0" : "text-sm	lg:text-lg	2xl:text-xl	font-light	mb-2 lg:mb-4 2xl:mb-8"}>{children}</p>  );
		case "p6":
			return ( <p className={nopadding==='yes' ? "text-sm	lg:text-base	2xl:text-lg	font-light	mb-0" : "text-sm	lg:text-base	2xl:text-lg	font-light	mb-2 lg:mb-4 2xl:mb-8"}>{children}</p>  );
		default: 
			return ( <p className={nopadding==='yes' ? "text-lg	text-xl	text-2xl	font-light	mb-0" : "text-lg	text-xl	text-2xl	font-light	mb-2 lg:mb-4 2xl:mb-8"}>{children}</p>  );
	  };
};

export default Type;



export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

/*
export const handleLogin = ({ username, password }) => {
  if (username === process.env.COREUSER && password === process.env.COREPASS) {
    return setUser({
      username: process.env.COREUSER,
      name: process.env.COREPASS,
      email: `johnny@example.org`,
    })
  }
*/


export const handleLogin = ({ username, password }) => {
  if (username === 'demo' && password === '3eX*cV4dLyAVqZ-7@nno') {
    return setUser({
      username: 'demo',
      name: 'Demo',
      email: `demo@pathventures.io`,
    })
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = callback => {
  setUser({})
  callback()
}

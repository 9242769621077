import React from "react"
import { Link, navigate } from "gatsby"

import Container from "./Container"
import Type from "../../components/typography/Type"
import { getUser, isLoggedIn, logout } from "../../services/auth"

	const navigation = [

  { name: 'Journeys', href: '/journeys' },
]

export default function MainNav() {
	

	
	let greetingMessage = ""
	  if (isLoggedIn()) {
		greetingMessage = `Hello ${getUser().name}`
	  } else {
		greetingMessage = "You are not logged in"
	  }
	
  return (
	<header className="bg-blue">
	<Container>
	  <nav className="mx-auto" aria-label="Top">
		<div className="flex flex-row items-center w-full justify-between py-10 mb-28">
		  <div className="flex items-center">
			<div>
				<div className="mt-2">
					<img src="https://ik.imagekit.io/pathventures/brand/logo/PathLogoWordmarkColour.svg" alt="Path" className="w-16 mb-2"/>
				</div>
				<a href="/" className="text-left">
					<Type type="h4" nopadding="yes">Benchmark</Type>
				</a>
			</div>
		  </div>
			<div className="ml-28 hidden space-x-8 lg:block">
			
				{isLoggedIn() ? (
					<div className="">
				  {navigation.map((link) => (
					  <a key={link.name} href={link.href} className="hover:border-b-2 border-black font-bold py-2">
						{link.name}
					  </a>
					))}
					</div>
				) : null}
			
			

			</div>
			<div className="grow">
			
			<div className="flex flex-row ">
			  	<div className="ml-auto mr-4 inline py-2">{greetingMessage}</div>
				{isLoggedIn() ? (
				  <a
					href="/"
					onClick={event => {
					  event.preventDefault()
					  logout(() => navigate(`/app/login`))
					}}
				  >
				  <button className="bg-black py-2 px-4 border border-black hover:bg-black/0 hover:text-black text-white text-base rounded-md">
					Logout
				  </button>
				  </a>
				) : 
				<a
					href="/app/login"
				  >
			<button className="bg-black py-2 px-4 border border-black hover:bg-black/0 hover:text-black text-white text-base rounded-md">
				Login
			  </button>
				</a>
			}
			</div>
		  </div>
		</div>
	  </nav>
	</Container>
	</header>
  )
}


import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Type from "../../components/typography/Type"




const SearchProductGroups = () => {


 
  const data = useStaticQuery
	(graphql`
	  query ProductGroupQuery {
		productGroups: allAirtable(
		  filter: { table: { eq: "ProductGroups" }}      ) {
		  edges {
			node {
			  recordId
			  data {
				Name
				Path
				JourneyGroups  {
					data {
						GroupName
						Path
					}				}
			  }
			}
		  }
		}
	  }
	`);

  return (

	<div className="">
	  {data.productGroups.edges.map((edge) => (
	  	<div>
			<Type type="h6" nopadding="yes">{edge.node.data.Name}</Type>
			<div className="py-2 mb-2">
				{edge.node.data.JourneyGroups.map((array) => (
				<Type type="p6" nopadding="yes"><a href={array.data.Path}>{array.data.GroupName}</a></Type>
				))}
			</div>
		</div>
	   ))}
	</div>
  );

};
  
export default SearchProductGroups;
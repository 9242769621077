
import React from "react"
import { Link } from "gatsby"



const LoginPrompt = () => {
	
	  
	  return (
		  <div className="">
		  <Link className="underline" to="/app/login">Log in</Link> to access Benchmarks
		  </div>
)
};

export default LoginPrompt;


import React from "react"
import Type from "../../components/typography/Type"


const IndustryCard = (props) => {	
	
const title = props.title;
const url = props.url;
		
	
	return (
		
		<a href={url}>
	
			<div className="w-full border hover:border-black border-black/25 flex items-center px-8 py-16">
						<div className="w-full"><Type type="p5" nopadding="yes">{title}</Type></div>
			</div>
		  </a> 
		
	);
};

export default IndustryCard;

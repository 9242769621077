
import React from "react"
import Type from "../../components/typography/Type"

import SearchProductGroups from "../../components/search/SearchProductGroups"
import SearchIndustries from "../../components/search/SearchIndustries"
import SearchOrganisations from "../../components/search/SearchOrganisations"

import { useState } from "react"


//import SearchIndustries from "../../components/search/SearchIndustries"
//import SearchStages from "../../components/search/SearchStages"
//import SearchProductGroups from "../../components/search/SearchProductGroups"

const SearchNav = (props) => {
	
	
	  const [isExpandedIndustry, toggleExpansionIndustry] = useState(false);
	  const [isExpandedOrganisations, toggleExpansionOrganisations] = useState(false);
	  const [isExpandedProductGroups, toggleExpansionProductGroups] = useState(false);

	  
	  return (
	<div>
	{/*}<SearchIndustries/>
	<SearchStages/>
	<SearchProductGroups/>
	*/}
	<ul>

		<li className="pb-8 border-b mb-8">
			<div className="flex flex-row">
			<div className=""><Type type="p5" nopadding="yes">Industries</Type></div>
			<div className="grow flex justify-end">
			<button onClick={() => toggleExpansionIndustry(!isExpandedIndustry)} className=""><div className="font-light text-2xl text-black">+</div></button>
			</div>
			</div>
			<div className={`${ isExpandedIndustry ? `block` : `hidden` } mt-4`}>
			<SearchIndustries/>
			</div>
		</li>
		<li className="pb-8 border-b mb-8">
			<div className="flex flex-row">
			<div className=""><Type type="p5" nopadding="yes">Organisations</Type></div>
			<div className="grow flex justify-end">
			<button onClick={() => toggleExpansionOrganisations(!isExpandedOrganisations)} className="font-light text-2xl text-black">+</button>
			</div>
			</div>
			<div className={`${ isExpandedOrganisations ? `block` : `hidden` } mt-4 `}>
				<SearchOrganisations/>
			</div>
		</li>
		<li className="pb-8 border-b mb-8">
			<div className="flex flex-row">
			<div className=""><Type type="p5" nopadding="yes">Product groups</Type></div>
			<div className="grow flex justify-end">
			<button onClick={() => toggleExpansionProductGroups(!isExpandedProductGroups)} className="font-light text-2xl text-black">+</button>
			</div>
			</div>
			<div className={`${ isExpandedProductGroups ? `block` : `hidden` } mt-4 `}>
				<SearchProductGroups/>
			</div>
		</li>
	</ul>
	</div>
)
};

export default SearchNav;

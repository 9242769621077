import React from "react"


const Container = ({ children }) => {
  return (
	<div className="container px-8 sm:px-12 mx-auto"> 
		{children}
	</div>
  )
}

export default Container

import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout/Layout"
import Container from "../../../components/layout/Container"
import Type from "../../../components/typography/Type"
import SearchNav from "../../../components/search/SearchNav"
import IndustryCard from "../../../components/cards/IndustryCard"


import { Router } from "@reach/router"
import { getUser, isLoggedIn } from "../../../services/auth"
import LoginPrompt from "../../../components/app/loginPrompt"





export default function Pages( {data} ) {
return (
  <Layout>
  <Container>
  
  {isLoggedIn() ? (
  <>
  
  <div className="flex flex-col lg:flex-row lg:space-x-16">
<div className="w-full lg:w-3/12">
 <SearchNav industry={data.airtable.data.Path}/>
</div>
<div className="w-full lg:w-9/12 mt-12 lg:mt-0">
<Type type="h3">{data.airtable.data.Name} journeys</Type>

{data.airtable.data.ProductGroups.map((array) => (
  <div>
  <Type type="h4">{array.data.Name}</Type>
  <div className="grid grid-cols-3 gap-8 mb-12">
  {array.data.JourneyGroups.map((groupsArray) => (
    <>
  <IndustryCard
    url={groupsArray.data.Path}
    title={groupsArray.data.GroupName}
    />
  </>
  ))}
  </div>
  </div>
))}

</div>
  </div>
  
  </>
  ) : (
    <>
      <LoginPrompt/>
    </>
  )}
  

  
  </Container>
  </Layout>
  )
}



export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Industry" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        Journeys {
          data {
            JourneyName
            Path
            ScreenshotURLHero
            OrganisationTxt
          }
        }
       
        ProductGroups {
          data {
            Name
            Path
            JourneyGroups {
              data {
                GroupName
                Path
              }
            }
          }
        }
        Organisations { 
          data 
          {
            Name
            Path
          }
        }
      }
    }
  }
`

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Type from "../../components/typography/Type"

const SearchIndustries = () => {


  const data = useStaticQuery
	(graphql`
	  query IndustriesQuery {
		industries: allAirtable(
		  filter: { table: { eq: "Industry" }}      ) {
		  edges {
			node {
			  recordId
			  data {
				Name
				Path
			  }
			}
		  }
		}
	  }
	`);

  return (

	<div className="">
	  {data.industries.edges.map((edge) => (
		<div>
			<a href={edge.node.data.Path}><Type type="h6">{edge.node.data.Name}</Type></a>
		</div>
	   ))}
	  </div>
  );

};
  
export default SearchIndustries;